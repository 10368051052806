import React from 'react';

import { colors } from '../../styles/variables';

import { SectionAppearanceProvider } from '../../components/sections/SectionContext';

import Seo from '../../components/seo';
import Navbar from '../../components/organisms/Navbar';
import {
    SectionHeroStart,
    SectionOrderedItems,
    SectionIconItems,
    SectionAccordion,
    SectionInlineTiles,
    SectionBigTiles,
} from '../../components/sections';

import {
    START_EN_NAVBAR_CONFIG,
    START_EN_HERO_CONFIG,
    START_EN_ORDERED_ITEMS_CONFIG,
    START_EN_ICON_ITEMS_CONFIG,
    START_EN_ACCORDION_SECTION,
    START_EN_INLINE_TILES_CONFIG,
    START_EN_BIG_TILES_CONFIG,
} from '../../content_config_start_en';

const Index = () => (
    <div className="page-body sticky-navbar">
        <SectionAppearanceProvider>
            <Seo title="Start PayPo.pl" />
            <Navbar
                config={ START_EN_NAVBAR_CONFIG }
                sticky
            />
            <SectionHeroStart
                config={ START_EN_HERO_CONFIG }
            />
            <SectionOrderedItems
                config={ START_EN_ORDERED_ITEMS_CONFIG }
            />
            <SectionIconItems
                config={ START_EN_ICON_ITEMS_CONFIG }
                background={ colors.grayCultured }
            />
            <SectionAccordion
                config={ START_EN_ACCORDION_SECTION }
                isColorSectionAbove={ true }
            />
            <SectionInlineTiles
                config={ START_EN_INLINE_TILES_CONFIG }
            />
            <SectionBigTiles
                config={ START_EN_BIG_TILES_CONFIG }
            />
        </SectionAppearanceProvider>
    </div>
);

export default Index;
