import {
    ItemBag,
    ItemBlocks,
    ItemBox,
    ItemBoxes,
    ItemCalendar,
    ItemGift,
    ItemMoney,
    ItemStopwatch,
} from './assets/images';

import { colors } from './styles/variables';

import {
    heroRef,
    howItWorksRef,
    benefitsRef,
    faqRef,
    opinionsRef,
    contactRef,
} from './utils/sectionRefs';

export const START_EN_NAVBAR_CONFIG = {
    navigationLinks: [
        {
            id: 1,
            name: "How does it work?",
            relatedSection: howItWorksRef.navigationRef,
            action: () => howItWorksRef.moveToSection(),

        },
        {
            id: 2,
            name: "Why PayPo?",
            relatedSection: benefitsRef.navigationRef,
            action: () => benefitsRef.moveToSection(),
        },
        {
            id: 3,
            name: "FAQ",
            relatedSection: faqRef.navigationRef,
            action: () => faqRef.moveToSection(),
        },
        {
            id: 4,
            name: "Reviews",
            relatedSection: opinionsRef.navigationRef,
            action: () => opinionsRef.moveToSection(),
        },
        {
            id: 5,
            name: "Contact",
            relatedSection: contactRef.navigationRef,
            action: () => contactRef.moveToSection(),
        },
    ],
};

export const START_EN_HERO_CONFIG = {
    title: "Buy now, pay in 30 days",
    id: "section-hero",
    forwardedRef: heroRef.forwardedRef,
    navigationRef: heroRef.navigationRef,
    subtitle: "",
    content: [
        {
            text: "Place your order as usual and choose PayPo during checkout. You pick up your purchases, check if they suit you, and pay up to 30 days after placing the order. No additional cost.",
        },
    ],
    heroGraphic: "start",
};

export const START_EN_ORDERED_ITEMS_CONFIG = {
    title: "How does it work?",
    id: "section-how-it-works",
    forwardedRef: howItWorksRef.forwardedRef,
    navigationRef: howItWorksRef.navigationRef,
    itemList: [
        {
            id: 1,
            title: "Step 1",
            text: "During shopping you choose PayPo as your payment method.",
        },
        {
            id: 2,
            title: "Step 2",
            text: "If you don't already have a PayPo account, you enter your information. You only confirm subsequent purchases with an SMS code or one click on the mobile app.",
        },
        {
            id: 3,
            title: "Step 3",
            text: "PayPo pays for your purchases, and you receive your order.",
        },
        {
            id: 4,
            title: "Step 4",
            text: "Check products at home and pay in 30 days for items that you decided to keep.",
        }
    ],
    squares: [
        {
            top: "11.5rem",
            left: "-17rem",
            size: "20rem",
            bgColor: colors.paypoGreen500,
            rotate: "45deg",
        }
    ],
};

export const START_EN_VIDEO_CONTAINER_CONFIG = {
    videoId: "v_uzBRJeZOU",
    squares: [
        {
            top: "6rem",
            left: "7.2rem",
            size: "4.8rem",
            bgColor: colors.fuchsia500,
            rotate: "81.39deg",
        },
        {
            top: "33rem",
            right: "7.2rem",
            size: "4.8rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            top: "11rem",
            right: "-15.5rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const START_EN_ICON_ITEMS_CONFIG = {
    title: "Why PayPo?",
    id: "section-benefits",
    forwardedRef: benefitsRef.forwardedRef,
    navigationRef: benefitsRef.navigationRef,
    content: [
        {
            Icon: ItemCalendar,
            description: "You have up to 30 days to pay - <b>at no extra cost</b>",
        },
        {
            Icon: ItemBlocks,
            description: "<b>You benefit from attractive promotions</b> and dedicated discount codes from our partners.",
        },
        {
            Icon: ItemBoxes,
            description: "<b>Try products at home</b>, return the unwanted items and pay for ones you decided to keep",
        },
        {
            Icon: ItemGift,
            description: "<b>Treat yourself </b> - enjoy big, seasonal sale or make someone a nice gift",
        },
        {
            Icon: ItemBag,
            description: "<b>You shop with confidence</b> - you receive and check your order first, and only pay when you are sure of your purchase",
        },
        {
            Icon: ItemBox,
            description: "<b>You don't miss unique opportunities</b> - you buy when it's most worthwhile and pay when it's convenient for you",
        },
        {
            Icon: ItemMoney,
            description: "Your <b>transaction limit will raise</b> with regular purchases",
        },
        {
            Icon: ItemStopwatch,
            description: "<b>Instant and secure payments</b> - you confirm subsequent transactions with just an SMS code or one click in the app",
        },
    ],
    additionalText: [
        {
            text: 'The service is available to new PayPo customers and at selected stores. The information does not constitute an offer. For details, see the Terms and Conditions of the service available at <a href=\'https://www.paypo.pl\'>www.paypo.pl</a> in Polish under the name: Regulamin usługi "Zapłać za 30 dni". PayPo customers, after paying off one transaction in full, begin to use the service, which allows you to pay off your order within 30 days or in installments. <a href=\'https://www.paypo.pl/#section-state-control-buttons\'>Learn more</a>.',
        },
    ],
    squares: [
        {
            bottom: "-14rem",
            right: "-13rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const START_EN_ACCORDION_SECTION = {
    title: "FAQ",
    id: "section-faq",
    forwardedRef: faqRef.forwardedRef,
    navigationRef: faqRef.navigationRef,
    content: [
        {
            id: "1",
            title: "1. Do I need to provide my credit/debit card details?",
            text: "No, we do not require credit/debit card details to use PayPo payment.",
            isExpanded: true,
        },
        {
            id: "2",
            title: "2. How do I pay for the order?",
            text: "You can settle the balance easily, paying by bank transfer or postal order. You can also use quick online payments.  All payment details will be sent to you via email along with the order confirmation. You can also find a payments schedule and make a payment in your Customer Panel.",
        },
        {
            id: "3",
            title: "3. Is it safe to use?",
            text: "Yes, all payments are made as a part of an encrypted connection. We also never ask to provide login details to your bank account or to provide credit/debit card details.",
        },
        {
            id: "4",
            title: "4. Is it cost free?",
            text: "Yes. You have up to 30 days to pay for your order at no extra cost.",
        },
        {
            id: "5",
            title: "5. When do I have to repay the transaction?",
            text: "You have up to 30 days to repay your order. We will remind you of the upcoming repayment deadline via SMS and email.",
        },
        {
            id: "6",
            title: "6. What happens if I return my order?",
            text: "You won't pay for items you’ve returned. Shop will send us a notification on your return and your transaction will be simply cancelled in our system.",
        },
    ],
    squares: [
        {
            top: "54rem",
            left: "13rem",
            size: "6rem",
            bgColor: colors.fuchsia500,
            rotate: "78.54deg",
        },
        {
            top: "6rem",
            right: "10rem",
            size: "10rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            top: "-6rem",
            right: "-13rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const START_EN_INLINE_TILES_CONFIG = {
    title: "Check out more than 27000 positive reviews",
    id: "section-opinions",
    forwardedRef: opinionsRef.forwardedRef,
    navigationRef: opinionsRef.navigationRef,
    tileList: [
        {
            id: 1,
            score: '5.0',
            name: "Monika",
            text: "I am very happy with my payments with PayPo. Whenever I need something, I can shop quickly and efficiently and then make a repayment at a convenient time.",
        },
        {
            id: 2,
            score: '5.0',
            name: "Magdalena",
            text: "Brilliant - for me, the fastest and greatest way to pay for parcels that I can look at, test, try on or check at home in peace. No nerves, no rush and no unnecessary freezing of cash. And so secure and safe at the same time. I RECOMMEND",
        },
        {
            id: 3,
            score: '5.0',
            name: "Ala",
            text: "I have never been disappointed with the PayPo service. Readable and clear rules. Good notification system. I use and will continue to use.",
        },
    ],
    additionalText: [
        {
            text: 'The opinions presented are from Opineo. Verification of opinions is done in accordance with the Regulations for Opineo.pl users.',
        },
    ],
    cta: {
        type: "button",
        text: "See opinions on Opineo",
        link: "https://www.opineo.pl/opinie/paypo-pl",
    },
    squares: [
        {
            bottom: "18rem",
            left: "-8rem",
            size: "10rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            bottom: "-4.8rem",
            left: "-12rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
        {
            top: "24.8rem",
            right: "31rem",
            size: "14rem",
            bgColor: colors.paypoGreen500,
            rotate: "69.24deg",
        },
    ],
};

export const START_EN_BIG_TILES_CONFIG = {
    title: "Any questions?",
    id: "section-contact",
    forwardedRef: contactRef.forwardedRef,
    navigationRef: contactRef.navigationRef,
    tileList: [
        {
            id: 1,
            bgColor: colors.mintCream,
            content: {
                title: "bok@paypo.pl",
                textContent: [
                    {
                        id: 1,
                        text: "We’ll get back to you in 48 hours",
                    },
                ],
                cta: {
                    type: "button",
                    action: "email",
                    text: "Email us"
                }
            },
        },
        {
            id: 2,
            bgColor: colors.palePurple,
            content: {
                title: "+48 22 333 74 60",
                textContent: [
                    {
                        id: 1,
                        text: "Our lines are open Monday to Friday from 8am to 6pm.",
                    },
                    {
                        id: 2,
                        text: "Call charge according to the operator's tariff",
                    },
                ],
                cta: {
                    type: "textButton",
                    action: "phoneCall",
                    text: "Call us",
                },
            },
        },
    ],
    squares: [
        {
            top: "-15.2rem",
            left: "-12rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};
